/* Main CSS file which will be included in every HTML page shown */

/* First load the variables */
@use "./foundation/var";

/* Afterwards load the styles */
@use "./extra/normalize";
@use "./extra/boilerplate";
@use "./extra/flex-presets";
@use "./extra/markdown";
